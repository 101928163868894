import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WreAuthlibModule } from 'wre-authlib';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { environment } from 'src/environments/environment';
import { MenuModule } from '@progress/kendo-angular-menu';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { PlaceholderComponent } from './components/placeholder/placeholder.component';
import { InsightsModule } from './error-handling/insights.module';
import { ErrorInterceptor } from './error-handling/http-error.interceptor';
import { SortableModule } from '@progress/kendo-angular-sortable';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { DisclaimerComponent } from './components/disclaimer-modal/disclaimer-modal.component';
import { KendoModule } from 'src/app/shared/kendo.module';
import { AboutComponent } from './components/about/about.component';
 

const externalAuthConfig = {
  protectedResourceMap: environment.b2cProtectedResourceMap,
  customScopes: environment.b2cCustomScopes,
  auth: {
    clientId: environment.b2cClientId,
    authority: environment.b2cAuthority,
    redirectUri: environment.redirectUri,
    postLogoutRedirectUri: environment.postLogoutRedirectUri,
    knownAuthorities: environment.b2cKnownAuthorities
  },
  cache: {
    cacheLocation: "localStorage",
  },
  wreSignoutBaseUri: environment.postLogoutRedirectUri,
  applicationName: environment.applicationName,
  clientId: environment.b2cClientId,
  cacheTimeInSeconds: environment.rolesCacheSeconds,
  authenticationCacheSeconds: environment.authenticationCacheSeconds,
  authorisationUrl: environment.authorisationUrl,
  resetPasswordAuthority: environment.b2cResetPasswordAuthority
};

const internalAuthConfig = {
  protectedResourceMap: environment.protectedResourceMap,
  auth: {
    clientId: environment.clientId,
    authority: environment.authority,
    redirectUri: environment.redirectUri,
    postLogoutRedirectUri: environment.postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
  },
  wreSignoutBaseUri: environment.postLogoutRedirectUri,
  applicationName: environment.applicationName,
  clientId: environment.clientId,
  cacheTimeInSeconds: environment.rolesCacheSeconds,
  authenticationCacheSeconds: environment.authenticationCacheSeconds,
  authorisationUrl: environment.authorisationUrl,
}

@NgModule({
  declarations: [
      AppComponent,
      PlaceholderComponent,
      DisclaimerComponent,
      AboutComponent
    ],
  imports: [
    KendoModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,    
    NgbModule,    
    WreAuthlibModule.forRoot(
        internalAuthConfig, 
        externalAuthConfig
        //custom cookie domain - needed while we don't have a proper domain
        //environment.cookieDomain
        ),   
    RouterModule,
    AppRoutingModule,    
    HttpClientModule,
    SharedModule,
    DropDownsModule,
    ExcelExportModule, 
    MenuModule,
    ProgressBarModule,
    InsightsModule,
    SortableModule,

    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
   ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
