import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-placeholder',
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.css']
})
export class PlaceholderComponent {

  constructor(public router: Router ) {
  }
}
