<kendo-dialog 
    *ngIf="dialogOpened" 
    (close)="cancel()" 
    maxWidth="600px" 
    width="60%"
    [maxHeight]="maxHeight"
    >
    <kendo-dialog-titlebar class="titleBar">
        <div class="title-bar">
            <label>{{ 'NOTIFICATION.TITLE' | translate }}</label>
        </div>
    </kendo-dialog-titlebar>
    <div class="loader" *ngIf="!userSetState">
        <kendo-loader>
        </kendo-loader>
    </div>
    <div *ngIf="userSetState">
        <table>
            <tr *ngIf="!isExternal">
                <td>                    
                    <input
                      class="checkbox"
                      type="checkbox"
                      id="ApprovalStatus"
                      #approvalStatus
                      [indeterminate]="userSetState.watchApprovalStatus == null"
                      kendoCheckBox
                      [(ngModel)]="userSetState.watchApprovalStatus"
                    />
                </td>
                <td>
                    <kendo-label class="label" [for]="approvalStatus" [text]="'NOTIFICATION.APPROVALSTATUS_LABEL' | translate"></kendo-label>
                    <div class="descriptor">{{ 'NOTIFICATION.APPROVALSTATUS_DESCRIPTION' | translate }}</div>
                </td>
            </tr>
            <tr *ngIf="hasCarrierEntities()">
                <td>                    
                    <input
                      class="checkbox"
                      type="checkbox"
                      id="FinancialDetails"
                      #financialDetails
                      [indeterminate]="userSetState.watchFinancialDetails == null"
                      kendoCheckBox
                      [(ngModel)]="userSetState.watchFinancialDetails"
                    />
                </td>
                <td>
                    <kendo-label class="label" *ngIf="!hasCarrierEntities() || !hasMgaEntities()" [for]="financialDetails" [text]="'NOTIFICATION.FINANCIALDETAILS_LABEL' | translate"></kendo-label>
                    <kendo-label class="label" *ngIf="hasCarrierEntities() && hasMgaEntities()" [for]="financialDetails" [text]="('NOTIFICATION.FINANCIALDETAILS_LABEL' | translate) + ' ' + ('NOTIFICATION.CARRIERS_ONLY' | translate)"></kendo-label>     
                    <div class="descriptor">{{ 'NOTIFICATION.FINANCIALDETAILS_DESCRIPTION' | translate }}</div>
                </td>
            </tr>
            <tr>
                <td>                    
                    <input
                      class="checkbox"
                      type="checkbox"
                      id="EntityDetails"
                      #entityDetails
                      [indeterminate]="userSetState.watchCarrierDetails == null"
                      kendoCheckBox
                      [(ngModel)]="userSetState.watchCarrierDetails"
                    />
                </td>
                <td *ngIf="hasCarrierEntities() && hasMgaEntities()">
                    <kendo-label class="label" [for]="entityDetails" [text]="'NOTIFICATION.ENTITYDETAILS_LABEL' | translate"></kendo-label>
                    <div class="descriptor">{{ 'NOTIFICATION.ENTITYDETAILS_DESCRIPTION' | translate }}</div>
                </td>
                <td *ngIf="hasCarrierEntities() && !hasMgaEntities()">
                    <kendo-label class="label" [for]="entityDetails" [text]="'NOTIFICATION.CARRIERDETAILS_LABEL' | translate"></kendo-label>
                    <div class="descriptor">{{ 'NOTIFICATION.CARRIERDETAILS_DESCRIPTION' | translate }}</div>
                </td>
                <td *ngIf="hasMgaEntities() && !hasCarrierEntities()">
                    <kendo-label class="label" [for]="entityDetails" [text]="'NOTIFICATION.MGADETAILS_LABEL' | translate"></kendo-label>
                    <div class="descriptor">{{ 'NOTIFICATION.MGADETAILS_DESCRIPTION' | translate }}</div>
                </td>
            </tr>
            <tr *ngIf="hasCarrierEntities()">
                <td>                    
                    <input
                      class="checkbox"
                      type="checkbox"
                      id="CarrierRatings"
                      #carrierRatings
                      [indeterminate]="userSetState.watchCarrierRatings == null"
                      kendoCheckBox
                      [(ngModel)]="userSetState.watchCarrierRatings"
                    />
                </td>
                <td>
                    <kendo-label class="label" *ngIf="!hasCarrierEntities() || !hasMgaEntities()" [for]="carrierRatings" [text]="'NOTIFICATION.CARRIERRATINGS_LABEL' | translate"></kendo-label>
                    <kendo-label class="label" *ngIf="hasCarrierEntities() && hasMgaEntities()" [for]="carrierRatings" [text]="('NOTIFICATION.CARRIERRATINGS_LABEL' | translate) + ' ' + ('NOTIFICATION.CARRIERS_ONLY' | translate)"></kendo-label>                    
                    <div class="descriptor">{{ 'NOTIFICATION.CARRIERRATINGS_DESCRIPTION' | translate }}</div>
                </td>
            </tr>
            <tr>
                <td>                    
                    <input
                      class="checkbox"
                      type="checkbox"
                      id="Documents"
                      #documents
                      [indeterminate]="userSetState.watchDocuments == null"
                      kendoCheckBox
                      [(ngModel)]="userSetState.watchDocuments"
                    />
                </td>
                <td>
                    <kendo-label class="label" [for]="documents" [text]="'NOTIFICATION.DOCUMENTS_LABEL' | translate"></kendo-label>
                    <div class="descriptor">{{ 'NOTIFICATION.DOCUMENTS_DESCRIPTION' | translate }}</div>
                </td>
            </tr>
        </table>
    </div>
    <kendo-dialog-actions layout="end">
        <button kendoButton id="cancel-btn" (click)="cancel()" fillMode="outline" class="button-size outline">{{ 'NOTIFICATION.CANCEL' | translate }}</button>
        <button kendoButton id="confirm-btn" (click)="confirm()" themeColor="primary" class="button-size"
            [disabled]="
                originalState == null || (
                originalState.watchCarrierDetails == userSetState?.watchCarrierDetails &&
                originalState.watchCarrierRatings == userSetState?.watchCarrierRatings &&
                originalState.watchDocuments == userSetState?.watchDocuments &&
                originalState.watchFinancialDetails == userSetState?.watchFinancialDetails &&
                originalState.watchApprovalStatus == userSetState?.watchApprovalStatus
                )
            "
        >{{ 'NOTIFICATION.CONFIRM' | translate }}</button>
    </kendo-dialog-actions>
</kendo-dialog>