import { ErrorHandler, NgModule } from "@angular/core";
import { ApplicationInsightsService } from "./application-insights.service";
import { ApplicationinsightsAngularpluginErrorService } from "@microsoft/applicationinsights-angularplugin-js";


@NgModule({
    providers: [
        ApplicationInsightsService,
      {
        provide: ErrorHandler,
        useClass: ApplicationinsightsAngularpluginErrorService,
      },
    ],
  })
  export class InsightsModule {
      constructor(private insights: ApplicationInsightsService) {}
  }