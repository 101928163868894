import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Message, MessageType } from 'src/app/model/message';

@Component({
  selector: 'app-toast-template',
  templateUrl: './toast-template.component.html',
  styleUrls: ['./toast-template.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ToastTemplateComponent {
  @Input() message: Message | null = null;

  constructor(
  ){

  }  

  getMessageClass(postfix: string): string {
    switch(this.message?.type){
        case MessageType.Info: {
            return 'info' + postfix;
        }
        case MessageType.Success: {
            return 'success' + postfix;
        }
        case MessageType.Error: {
          return 'danger' + postfix;
        }
        case MessageType.Warning: {
          return 'warning' + postfix;
        }
        default: {
            return 'info' + postfix;
        }
    }
  }
}

