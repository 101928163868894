import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationStateService } from 'src/app/services/applicationState.service';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {
  public expanded: boolean = true;   
  public showNewEntityMenu: boolean = false;
  public isAdmin: boolean=false;
  public isInternalUser: boolean = false;

  public get currentRoute() : string {
    return this.router.url;
  }
  
  constructor(private router: Router,private sidebarService:SidebarService,
    private appStateService: ApplicationStateService) {

  }
  ngOnInit(): void {
    this.appStateService.UserRoles$.subscribe(response => {
      this.isAdmin = response.IsAdmin();
      this.isInternalUser = response.IsInternalRoleMember();
     });

    const innerWidth = window.innerWidth;
    if(innerWidth <= 768) { //tablet
      this.expanded = false;
    }
    this.sidebarService.sideBarExpanded$.subscribe(val => {
      this.expanded = val;
    });
  }

  toggle($event: any) {
    this.expanded = !this.expanded;
    this.showNewEntityMenu = false;
    this.sidebarService.toggleSideBar(this.expanded);
    $event.preventDefault();
    return false;
  }

  linkClick($event: any, targetRoute: string){
    this.router.navigate([targetRoute, { }])
    this.showNewEntityMenu = false;
    $event.preventDefault();
    return false;
  }

  toggleNewEntityMenu() {
    this.showNewEntityMenu = !this.showNewEntityMenu;
  }
}
