import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { GlobalErrorHandler } from './error-handler';
import { MessageService } from './message-service';
 
@Injectable()
export class ApplicationInsightsService {
    private angularPlugin = new AngularPlugin();
    private appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: environment.appInsightsIntrumentationKey,
            extensions: [this.angularPlugin],
            extensionConfig: {
                [this.angularPlugin.identifier]: {
                    router: this.router,
                    errorServices: [new GlobalErrorHandler(this.messageService, this)],
                },
            },
        },
    });
 
    constructor(private router: Router, private messageService: MessageService) {
        this.appInsights.loadAppInsights();
    }
 
    // expose methods that can be used in components and services
    trackEvent(name: string): void {
        this.appInsights.trackEvent({ name });
    }
 
    trackTrace(message: string): void {
        this.appInsights.trackTrace({ message });
    }

    logException(exception: Error, severityLevel: SeverityLevel = SeverityLevel.Error) {
        this.appInsights.trackException({
            exception: exception,
            severityLevel: severityLevel,
        });
    }
}