import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApplicationInsightsService } from './application-insights.service';
import { Message, MessageType } from '../model/message';
import { MessageService } from './message-service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private messageService: MessageService,
    private insightsService: ApplicationInsightsService
  ) {

  }

  handleError(error: any): void {
    if(error instanceof HttpErrorResponse) {
      // handled by interceptor
    } 
    else if (error.name === "TypeError" 
      && error.message == 'Cannot read properties of undefined (reading \'clientX\')')
    {
        // Trap and ignore kendo drag-drop error. We have disabled drag-drop on the grids but it still occurs.  
        return;
    }
    else 
    {
      this.messageService.sendMessage(<Message>{
        heading: 'Error:',
        content: `An error occurred in the Market Security Application. If this issue persists, please contact support indicating a client side error.`,
        type: MessageType.Error
      });
      this.insightsService.logException(error);
    }
    if(!environment.production) {
      console.error(error);
    }
  }
}