import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { Notification } from '../model/notification';

@Pipe({
  name: 'notificationTypePipe', pure: false
})
export class NotificationTypePipe extends TranslatePipe implements PipeTransform {

  override transform(query: string, notification: Notification): any {
    
    let itemsToTranslate: string[] = [];

    if (notification.watchApprovalStatus) {
      itemsToTranslate.push('NOTIFICATION_OPTIONS.APPROVALS')
    }

    if (notification.watchCarrierDetails) {
      itemsToTranslate.push('NOTIFICATION_OPTIONS.CARRIER_MGA_DETAILS')
    }

    if (notification.watchFinancialDetails) {
      itemsToTranslate.push('NOTIFICATION_OPTIONS.FINANCIAL_DETAILS')
    }

    if (notification.watchCarrierRatings) {
      itemsToTranslate.push('NOTIFICATION_OPTIONS.CARRIER_RATING')
    }

    if (notification.watchDocuments) {
      itemsToTranslate.push('NOTIFICATION_OPTIONS.DOCUMENTS')
    }

    const result = itemsToTranslate.map((i) => super.transform(i));

    return result.join(', ');
}

}