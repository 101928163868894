<div class="sidebar d-flex flex-column"
     [ngClass]="{'expanded': expanded, 'collapsed': !expanded}">
  <div class="sidebar-items-top">
    <!-- Add Entities -->
    <div class="nav-item"
         *ngIf="isAdmin">
      <button id="NewEntityMenuButton"
              (click)="toggleNewEntityMenu()"
              [ngClass]="{'clicked': currentRoute === '/admin'}"
              title="expanded ? null : '{{ 'SIDEBAR.ADD_ENTITY' | translate }}'"
              aria-label="Add entity">

        <span class="nav-icon">
          <i class="fa fa-plus icon"
             aria-label="Add entity"></i>
        </span>
        <span class="linkText"
              *ngIf="expanded">{{ 'SIDEBAR.ADD_ENTITY' | translate }}</span>
      </button>
      <div *ngIf="showNewEntityMenu"
           [ngClass]="{ 'new-entity-menu-collapsed' : !expanded }"
           class="sub-menu new-entity-menu">
        <div class="sub-menu-item">
          <button id="new-carrier"
                  class="new-entity-menu-item"
                  aria-label="new-carrier-button"
                  (click)="linkClick($event, '/admin/new-carrier')"
                  title="{{ 'SIDEBAR.NEW_CARRIER' | translate }}">
            <span class="new-entity-link-text sub-menu-link">{{ 'SIDEBAR.NEW_CARRIER' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
    <ng-template *ngTemplateOutlet="buttonItem; context: {$implicit: {
            id: 'search',
            name: 'Search',
            route: '/search',
            text: 'SIDEBAR.CARRIER_SEARCH',
            class: 'fa fa-search icon'
        }}"></ng-template>
    <ng-template *ngTemplateOutlet="buttonItem; context: {$implicit: {
            id: 'notifications',
            name: 'Notifications',
            route: '/notifications',
            text: 'SIDEBAR.NOTIFICATIONS',
            class: 'fa fa-bell icon'
        }}"></ng-template>
  </div>
  <div class="sidebar-items-bottom mt-auto">
    <div class="nav-item"
         *ngIf="isAdmin">
      <a routerLink="/admin/panel"
         class="sidebarLink">
        <span class="nav-icon">
          <i class="fa fa-user icon"
             aria-label="Admin Panel"></i>
        </span>
        <span class="linkText"
              *ngIf="expanded">{{
          "SIDEBAR.ADMIN_PANEL" | translate
          }}</span>
      </a>
    </div>
    
    <div class="sidebar-items-bottom mt-auto about-btn">
      <div class="nav-item"
           *ngIf="isInternalUser">
        <a routerLink="/about"
           class="sidebarLink">
          <span class="nav-icon">
            <i class="fa fa-info-circle icon"
               aria-label="About"></i>
          </span>
          <span class="linkText"
                *ngIf="expanded">{{
            "SIDEBAR.ABOUT" | translate
            }}</span>
        </a>
      </div>
      
    <div class="nav-item">
      <a href="mailto:gre_market_security@gallagherre.com"
         target="_blank"
         style="padding-left:6px">
        <span class="nav-icon">
          <i class="fa fa-envelope icon"
             aria-label="Support"></i>
        </span>
        <span class="linkText"
              *ngIf="expanded">{{ 'SIDEBAR.CONTACT' | translate }}</span>
      </a>
    </div>
    <div class="nav-item">
      <button id="collapseExpand"
              (click)="toggle($event)"
              [attr.title]="expanded ? null : 'Expand menu'"
              aria-label="Toggle Menu Expansion">
        <i class="fa fa-angle-double-left icon"
           aria-label="Collapse Menu"
           *ngIf="expanded"></i>
        <i class="fa fa-angle-double-right icon"
           aria-label="Expand Menu"
           *ngIf="!expanded"></i>
        <span class="linkText"
              *ngIf="expanded">{{ 'SIDEBAR.COLLAPSE_MENU' | translate }}</span>
      </button>
    </div>
  </div>
</div>

<ng-template #buttonItem
             let-data>
  <div class="nav-item">
    <button [id]="data.id"
            [attr.aria-label]="data.name + 'button'"
            [ngClass]="{'clicked': currentRoute === data.route}"
            (click)="linkClick($event, data.route)"
            [attr.title]="expanded ? null : data.text | translate">
      <span class="nav-icon">
        <i [class]="data.class"
           [attr.aria-label]="data.name"></i>
      </span>
      <div class="linkText"
           *ngIf="expanded">{{ data.text | translate }}</div>
    </button>
  </div>
</ng-template>