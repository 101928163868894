import { NgModule } from "@angular/core";
import { ButtonsModule } from "@progress/kendo-angular-buttons"; 
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from "@progress/kendo-angular-inputs";;
import { PagerModule } from '@progress/kendo-angular-pager';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { IconsModule } from '@progress/kendo-angular-icons';
import { MenusModule } from "@progress/kendo-angular-menu";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { LabelModule } from "@progress/kendo-angular-label";
import { PanelBarModule } from '@progress/kendo-angular-layout';
import { LayoutModule } from "@progress/kendo-angular-layout";
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import { DragAndDropModule } from "@progress/kendo-angular-utils";
import { SortableModule } from "@progress/kendo-angular-sortable";
import { ListBoxModule } from "@progress/kendo-angular-listbox";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { PDFExportModule } from "@progress/kendo-angular-pdf-export";
import { PopupModule } from "@progress/kendo-angular-popup";

@NgModule({
  imports: [ 
    TextBoxModule,
    InputsModule,
    IconsModule,
    ButtonsModule,
    GridModule,
    PagerModule,
    MenusModule,
    DropDownsModule,
    LabelModule,
    PanelBarModule,
    LayoutModule,
    DialogsModule,
    IndicatorsModule,
    DragAndDropModule,
    SortableModule,
    ListBoxModule,
    DateInputsModule,
    PDFExportModule,
    PopupModule
  ],
  exports: [
    TextBoxModule,
    InputsModule,
    IconsModule,
    ButtonsModule,
    GridModule,
    PagerModule,
    MenusModule,
    DropDownsModule,
    LabelModule,
    PanelBarModule,
    LayoutModule,
    DialogsModule,
    IndicatorsModule,
    DragAndDropModule,
    SortableModule,
    ListBoxModule,
    DateInputsModule,
    PDFExportModule,
    PopupModule
  ]
})
export class KendoModule { }
