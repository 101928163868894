import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs'; 
import { USER_PREFERENCES_INITIAL_STATE, UserPreferences } from '../model/userAccount';
import { Roles, USER_ROLE_INITIAL_STATE, UserRole } from '../model/roles';
import { AUTH_SERVICE, IAuthService, UserProfile } from 'wre-authlib';

export const USER_PROFILE_INITIAL_STATE = <UserProfile> {};

// singleton 
@Injectable({
  providedIn: 'root'
})
export class ApplicationStateService {

  private prefsUrl = environment.apiUrl + '/v1/useraccount/preferences';
  private initialised : Boolean = false;
  public UserPreferences$: BehaviorSubject<UserPreferences> = new BehaviorSubject(USER_PREFERENCES_INITIAL_STATE);
  public UserRoles$: BehaviorSubject<UserRole> = new BehaviorSubject(USER_ROLE_INITIAL_STATE);
  public UserProfile$: BehaviorSubject<UserProfile> = new BehaviorSubject(USER_PROFILE_INITIAL_STATE);

  constructor(
    private http:HttpClient, 
    @Inject(AUTH_SERVICE) private authService: IAuthService) {

  }  

  private getMyPreferences():Observable<UserPreferences>{
    return this.http.get<UserPreferences>(this.prefsUrl);
  }

  public async Initialise() {

    // Guard against multiple calls and wasted back-end invocations
    if (this.initialised) 
      return
    else
      this.initialised = true;

    await this.authService.getUser().then(x => {
      this.UserProfile$.next(x);
    }); 
    
    await this.reloadPreferences();
    
    await this.authService.getUserApplicationRoles().then((roles) => {
      const rolesArray: Roles[] = []
      roles.forEach(r => {
        rolesArray.push(<Roles>r.roleName);
      });
      this.UserRoles$.next(new UserRole(rolesArray));
    });
  }

  public reloadPreferences(): Promise<UserPreferences> {

    return new Promise((resolve, reject) => {
      this.getMyPreferences().subscribe(x => {
        this.UserPreferences$.next(x);
        resolve(x);
      }, _ => reject());
    });
    
  }

  public logout(): void {
    this.UserPreferences$.next(USER_PREFERENCES_INITIAL_STATE);
    this.UserRoles$.next(USER_ROLE_INITIAL_STATE);
    this.UserProfile$.next(USER_PROFILE_INITIAL_STATE);
    
    this.authService.logout();
  }
}