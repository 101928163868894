import { NgModule } from '@angular/core';
import { FooterComponent } from '../shared/footer/footer.component';
import { HeaderComponent } from '../shared/header/header.component';
import { LayoutComponent } from '../shared/layout/layout.component';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CommonModule } from '@angular/common';
import { DropDownListModule } from "@progress/kendo-angular-dropdowns";
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { IconsModule } from '@progress/kendo-angular-icons';
import { ToastTemplateComponent } from './toast-template/toast-template.component';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { TranslateModule } from '@ngx-translate/core'; 
import { DetailSummaryComponent } from './summary/detail-summary.component';
import { DialogModule } from '@progress/kendo-angular-dialog';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
   }
];
@NgModule({
    declarations: [
        LayoutComponent,
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        ToastTemplateComponent
    ],
    exports: [
        LayoutComponent,
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        ToastTemplateComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(routes),
        DropDownListModule,
        LabelModule,
        InputsModule,
        PopupModule,
        DialogModule,
        ButtonModule,
        IconsModule,        
        NotificationModule,
        TranslateModule
    ],providers:[DetailSummaryComponent]
})
export class SharedModule { }
