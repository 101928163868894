export interface UiOptionsResponse {
  countries: Option[];
  states: State[];
  entityTypes: string[];
  groups: Option[];
  ratingAgencies: RatingAgency[];  
  carrierTypes: Option[];
  taskTypes: Option[];
  scalingOptions: Option[];
  documentTypes: DocumentTypes[];
}

export interface DocumentTypes {
  id: string;
  description: string;
  plural: string;
}

export interface UiCarrierOptionsResponse {
  approvalStatuses: ApprovalOption[];
  sanctions: Option[];
}

export interface UiTaskOptionsResponse {
  taskTypes: Option[];
  priorities: Option[];
  taskStatuses: Option[];
  assignablePeople: Option[];
}

export interface State {
  country: string;
  state: string;
}

export interface Option{
  key:string;
  value:string;
}
export interface ApprovalOption{
  key:string;
  value:string;
  color:string
}

export interface RatingAgency{
  agencyId: number;
  agencyShortName: string;
  agencyLongName: string;
  ratings: Rating[];
}

export interface Rating {
  identifier: string;
  order: number;
}

export const FILTER_OPERATORS_NUMERIC: string[] = [
  '=',
  '<',
  '>',
  '>=',
  '<=',
];

export const FILTER_OPERATORS_STRING: string[] = [
  '='
];

export const ApprovalStatusFilterFieldName = 'ApprovalStatus'
export const CountryFilterFieldName = 'Country';
export const AmBestFilterFieldName = 'AmbRatingOrder';
export const CombinedRatioFilterFieldName = 'CombinedRatio';
export const CompanyTypeFilterFieldName = 'CompanyType';
export const EntityTypeFilterFieldName = 'EntityType';
export const FitchFilterFieldName = 'FitchRatingOrder';
export const NetWrittenPremiumFilterFieldName = 'NetWrittenPremium';
export const ShareHolderFundsFilterFieldName = 'ShareholderFunds';
export const SnpFilterFieldName = 'SnpRatingOrder';
export const SolvencyRatioFilterFieldName = 'SolvencyRatio';
export const SanctionsFilterFieldName = 'SanctionFlags';
export const GroupFilterFieldName = 'GroupName';
export const AsAtDateFilterFieldName = 'AsAtDateOrder';
export const AMBEST_RATING_AGENCY = 'AmBest Fin Strength Ratings';
export const FITCH_RATING_AGENCY = 'Fitch';
export const SNP_RATING_AGENCY = 'Standard & Poor';

export const NOTIFICATION_INTERNAL_OPTIONS : {text: string, value: number}[] = [
  { text: 'NOTIFICATION_OPTIONS.APPROVALS', value: 1 },
];
export const NOTIFICATION_UNIVERSAL_OPTIONS  : {text: string, value: number}[] = [
  { text: 'NOTIFICATION_OPTIONS.CARRIER_MGA_DETAILS', value: 2 }, 
  { text: 'NOTIFICATION_OPTIONS.FINANCIAL_DETAILS', value: 3 },
  { text: 'NOTIFICATION_OPTIONS.CARRIER_RATING', value: 4 },
  { text: 'NOTIFICATION_OPTIONS.DOCUMENTS', value: 5 }
];

export const DELETE_TITLE = 'DELETE_TITLE';
export const DELETE_CONFIRMATION = 'DELETE_CONFIRMATION';
