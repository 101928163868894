<div class="tab-flex-container ">

  <div id="contact-details">
    <h6 class="section-header-font">{{ entityType===entityTypeEnum.Carrier ? 'Carrier' :' MGA'}} details</h6>
    <div class="section">
      <div>
        <hr>
        <div class="small-container">
          <div class="border-line">
            <label class="text">Address line 1</label>
            <p class="desc">{{ details.addressLine1 ? details.addressLine1 : '-' }}</p>
          </div>
          <div class="border-line">
            <label class="text">Address line 2</label>
            <p class="desc">{{ details.addressLine2 ? details.addressLine2 : '-' }}</p>
          </div>
          <div class="border-line">
            <label class="text">City/town</label>
            <p class="desc">{{ details.city ? details.city : '-' }}</p>
          </div>
          <div class="border-line">
            <label class="text">Country </label>
            <p class="desc">{{ details.country ? details.country : '-' }}</p>
          </div>
          <div class="border-line">
            <label class="text">State/province/county</label>
            <p class="desc">{{ details.state ? details.state : '-' }}</p>
          </div>
          <div class="border-line">
            <label class="text">Post/zip code</label>
            <p class="desc">{{ details.postcode ? details.postcode : '-' }}</p>
          </div>
          <div class="border-line" *ngIf="entityType===entityTypeEnum.Carrier">
            <label class="text">Company Type </label>
            <p class="desc">{{ details?.carrierType ? details?.carrierType : '-' }}</p>
          </div>
          <div class="border-line">
            <label class="text">Group </label>
            <p class="desc">{{ details?.groupName ? details.groupName : '-' }}</p>
          </div>
          <div class="border-line">
            <label class="text">Captive</label>
            <p class="desc">
              {{ details.isCaptive ? 'Yes' : 'No' }}
            </p>
          </div>
          <div class="border-line" *ngIf="entityType===entityTypeEnum.Carrier">
            <label class="text">{{ "CARRIER_SUMMARY.UNDERLYING_SECURITY_FOR_MGA" | translate }}</label>
            <p class="desc">{{ details.servesAnyMgas ? 'Yes' : 'No' }}</p>
          </div>
          <div class="border-line" *ngIf="entityType===entityTypeEnum.MGA">
            <label class="text">Underlying Security</label>
            <p class="desc">{{ details.mgaCarriers && details.mgaCarriers.length > 0 ? details.mgaCarriers.length : 'No'
              }}</p>
          </div>
          <div class="border-line">
            <label class="text"> Contact number </label>
            <p class="desc">{{ details.telephone ? details.telephone : '-'}}</p>
          </div>
          <div class="border-line">
            <label class="text">Website</label>
            <p class="desc">
              <a class="cursor" (click)="goToLink(details.website)">{{ details.website }}</a>
            </p>
          </div>
          <div class="border-line">
            <label class="text"> CEO </label>
            <p class="desc">{{ details.ceo ? details.ceo : '-' }}</p>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div id="codes">
    <h6 class="section-header-font">Codes</h6>
    <div class="section">
      <hr>

      <div [ngClass]="entityType === entityTypeEnum.MGA ? 'no-ratings-grid' : 'grid-container'">
        <div class="border-line" *ngFor="let item of codes">
          <label class="text">{{transform(item.name) }}</label>
          <p class="desc">{{ item.code ? item.code :'-'}}</p>
        </div>
      </div>
    </div>
  </div>
  <div id="ratings-summary" *ngIf="entityType===entityTypeEnum.Carrier">
    <h6 class="section-header-font">Ratings summary</h6>
    <div class="section">
      <hr>

      <div class="grid-container" *ngFor="let item of ratings">
        <div>
          <div class="border-line">
            <label class="text">{{ item.code ? item.name + ' (' + item.code + ')' : item.name }}</label>
            <p class="desc">
              {{item.rating ? item.rating : '-'}}
              <span *ngIf="item.outlook"><br />{{item.outlook}}</span>
              <span *ngIf="item.date"><br /> {{ item.date | date : "MMM d, y" }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>