import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CrudActionResult } from "../model/crudActionResult";
import { Entity, EntityType } from "../model/entity-types";
import { UiNotificationState, SetNotificationRequest, NotificationResponse } from "../model/notification";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  notificationUrl = environment.apiUrl + '/v1/notification';

  constructor(private http: HttpClient) {
  }

  getNotifications(): Observable<NotificationResponse> {
    return this.http.get<NotificationResponse>(this.notificationUrl);
  }

  getNotification(id: number): Observable<Notification> {
    return this.http.get<Notification>(`${this.notificationUrl}/${id}`);
  }

  //TO DO - to remove type
  deleteNotification(id: number): Observable<any> {
    return this.http.delete<any>(`${this.notificationUrl}/${id}`);
  }

  setNotificationsState(entities: Entity[], state: UiNotificationState): Observable<CrudActionResult<number>> {    
    const request = <SetNotificationRequest>{
      WatchApprovalStatus: state.watchApprovalStatus,
      WatchCarrierDetails: state.watchCarrierDetails, // not a typo; the api is named in a dumb way
      WatchCarrierRatings: state.watchCarrierRatings,
      WatchDocuments: state.watchDocuments,
      WatchFinancialDetails: state.watchFinancialDetails,
      CarrierIds: entities.filter(x => x.entityType === EntityType.Carrier).map(x => x.id),
      MgaIds: entities.filter(x => x.entityType === EntityType.MGA).map(x => x.id),
    }
    
    return this.http.patch<CrudActionResult<number>>(this.notificationUrl, request);
  }

  getUiNotificationDialogState(entities: Entity[]): Observable<UiNotificationState> {   
    return this.http.post<UiNotificationState>(this.notificationUrl + '/bulk',  entities.map(x => {
     return {
      id: x.id,
      entityType: EntityType[x.entityType] // api expects string for some reason
     }; 
    }));
  }
}
