import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  public sideBarExpandedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public sideBarExpanded$ = this.sideBarExpandedSubject.asObservable();

  constructor() { }

  closeSidebar() {
    this.sideBarExpandedSubject.next(false);
  }
  
  toggleSideBar(value:boolean) {
    this.sideBarExpandedSubject.next(value);
  }
}
