<div class="cont">
    <h6 class="header">
        {{ 'NOTIFICATION.TITLE' | translate }}
    </h6>

    <div>

        <div class="row">
            <div class="col-md-3 col-sm-3 col-lg-3">
                <kendo-dropdownlist translate  [data]="notificationOptions" textField="text" valueField="value"
                    (valueChange)="handleManageNotifications($event.value)" [(ngModel)]="selectedNotificationOption">

                </kendo-dropdownlist>

            </div>
        </div>

        <div class="cont-element">
            <kendo-grid [scrollable]="'none'" [data]="gridData" id="notificationTable" [rowClass]="getRowClass" >
                <kendo-grid-column [width]="10" [class]="'editColClass'" [headerClass]="'border-bottom'" [id]="'edit-col'"
                    [headerClass]="'headerCustomClass'">
                    <ng-template kendoGridHeaderTemplate>

                        <label style=" display: none;">
                        </label>
                    </ng-template>

                    <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem="dataItem">
                        <button class="editNotification" id="editNotification{{rowIndex}}"
                            (click)="displayNotificationDialog(dataItem)">
                            <label style="display: none;" for="editNotification{{rowIndex}}">edit
                                icon-{{rowIndex}}</label>
                            <i class="fa fa-solid fa-pencil edit-icon" color="#838585"></i>
                        </button>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column [width]="15" [class]="'cellCustomClass'" [headerClass]="'border-bottom'"
                [headerClass]="'headerCustomClass'">
                <ng-template kendoGridHeaderTemplate>

                    <label style=" display: none;">
                    </label>
                </ng-template>

                <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem="dataItem">
                    <button class="deleteNotification" id="deleteNotification{{rowIndex}}"
                    (click)="promptUserBeforeDeleting(dialogActions,dataItem,rowIndex)">
                    <label style="display: none;" for="deleteNotification{{rowIndex}}">Delete
                        icon-{{rowIndex}}</label>
                    <i class="fa fa-solid fa-trash icon" color="#838585"></i>
                </button>
                </ng-template>
            </kendo-grid-column>
                <kendo-grid-column field="entityName" [title]="('NOTIFICATION_TABLE_HEADER.ENTITY' | translate)"
                    [class]="'cellCustomClass'" [width]="300">
                    <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem="dataItem">
                       <a (click)="navigateToEntity(dataItem)" class="entityName">{{dataItem.entityName}}</a>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="country" [title]="('NOTIFICATION_TABLE_HEADER.COUNTRY' | translate)"
                    [width]="150"> </kendo-grid-column>
                <kendo-grid-column field="gallagherCode"
                    [title]="('NOTIFICATION_TABLE_HEADER.GALLAGHER_CODE' | translate)" [width]="150">
                </kendo-grid-column>
               <kendo-grid-column field="notificationTypes" [title]="('NOTIFICATION_TABLE_HEADER.NOTIFICATION_TYPE' | translate)"
                    [width]="200"> 
                    <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem="dataItem">
                        {{ '' | notificationTypePipe:dataItem }}
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>

        </div>
    </div>

    <ng-template #dialogActions >
        <div class="custom-actions-layout">
        <button kendoButton (click)="close()">
            {{('NOTIFICATION.CANCEL' | translate)}}</button>
        <button kendoButton themeColor="primary" (click)="deleteNotification()">
            {{ ('DELETE_ACTION' | translate)}}
        </button>
        </div>
    </ng-template>
</div>
<div kendoDialogContainer > </div>
<app-notification-dialog [entities]="_entities" [events]="eventsSubject.asObservable()" [maxHeight]="'6000px'" (notifyLandingPage)="reloadNotifications($event)">    
