import { Injectable } from '@angular/core';
import { Message, MessageType } from '../model/message';
import { Guid } from 'guid-typescript'
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import { ToastTemplateComponent } from '../shared/toast-template/toast-template.component';
import { Subject, distinctUntilChanged } from 'rxjs';

interface notificationInstance {
    identifier: Guid,
    reference: NotificationRef,
    message: Message
}
  
@Injectable({ providedIn: 'root' })
export class MessageService {

    notifications: { [id: string] : notificationInstance; } = {};
    private messageQueue: Subject<Message> = new Subject<Message>;

    constructor(
        private notificationService: NotificationService
    ){
        this.messageQueue.subscribe(m => {
            this.sendMessageInternal(m);
        });
    }

    sendMessage(message: Message) {
        this.messageQueue.next(message);
    }

    sendMessageInternal(message: Message){
        let type: any;
        let hideAfter: number = -1;
        switch(message.type){
            case MessageType.Error: {
                type = { style: "info", icon: false };
                hideAfter=3000;
                break;
            }
            case MessageType.Warning: {
                type = { style: "warning", icon: false };
                hideAfter = 5000;
                break;
            }
            case MessageType.Success: {
                type = { style: "success", icon: false };
                hideAfter = 5000;
                break;
            }
            default: {
                type = { style: "info", icon: false };
                hideAfter = 5000;
                break;
            }
        }
    
        let notification = <notificationInstance>{
           message: message,
           identifier: Guid.create(), 
        }
        const ref = (
            this.notificationService.show({
                content: ToastTemplateComponent,
                closable: true,
                position: { horizontal: "right", vertical: "top" },
                animation: { type: "fade", duration: 400 },
                type: type,
                hideAfter: hideAfter // hideafter is ignored if manually closable with a close button, so we need to make it happen programatically.
            })
        );
        if(hideAfter > 0) {
            setTimeout(() => {
                if(this.notifications[notification.identifier.toString()]?.reference) {
                    if(this.notifications[notification.identifier.toString()].reference.hide) {
                        this.notifications[notification.identifier.toString()].reference.hide()
                    };
                }
            }, hideAfter);
        }
        ref.content?.setInput('message', message);
        ref.afterHide?.subscribe(x => {
            this.removeNotification(notification.identifier);
        });
        notification.reference = ref;

        this.notifications[notification.identifier.toString()] = notification;
    }

    private removeNotification(identifier: Guid){
        delete this.notifications[identifier.toString()];
    }

    clearMessages() {
        for (let key in this.notifications) {
            let value = this.notifications[key];
            value.reference.hide();
            value.reference.content?.destroy();
            this.removeNotification(value.identifier);
        }
    }
}
