
<div class="content-container">
  <app-header></app-header>
  <main class="main-container d-flex">
    <aside class="sidebar-container">
      <app-sidebar></app-sidebar>
    </aside>
    <section class="overflow-hidden w-100">
      <div class="section-container container-fluid">
        <router-outlet></router-outlet>
      </div>
      <app-footer class="footer-container"></app-footer>
    </section>
  </main>
</div>
