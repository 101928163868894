<div class="cont-wrapper">
    <kendo-dialog width="75vw" height="75vh" id="disclaimer">
        <kendo-dialog-titlebar class="disclaimer-title" id="titleBar">
            <label>{{ 'DISCLAIMER.TITLE' | translate }}</label>
        </kendo-dialog-titlebar>
        <div class="modal-container disclaimer-content" [innerHtml]="safeHtmlContent">
        </div>

        <kendo-dialog-actions layout="end">
            <span class="acceptBox">
                <input type="checkbox" class="acceptCheckbox" #acceptCheckbox kendoCheckBox [(ngModel)]="isAccepted"/>
                <kendo-label class="k-checkbox-label accept-check" [for]="acceptCheckbox" text="{{'DISCLAIMER.CHECKBOX_TEXT' | translate }}">
                </kendo-label>
            </span>
            <button kendoButton (click)="decline()" class="decline-btn me-3" id="decline-btn">{{'DISCLAIMER.DECLINE' | translate }}</button>
            <button kendoButton (click)="accept()" [disabled]="!isAccepted" class="accept-btn" id="accept-btn">{{ 'DISCLAIMER.ACCEPT' | translate }}</button>
        </kendo-dialog-actions>
    </kendo-dialog>
</div>