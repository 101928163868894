<footer>
  <div class="footer-container d-flex">
    
    <div class="footer-logo">
      <img class="logo" src="../../../assets/images/Gallagher Re Market Security Guard_Horizontal_Large.png" alt="GallagherRE logo"  />
    </div>
    <div class="linkContainer ms-auto">
      <!--<a href="#" id="cookieLink" class="link">{{ 'FOOTER.COOKIES' | translate }}</a>&nbsp;-->
      <a (click)="termsClick()" id="termsLink" class="link inline">{{ 'FOOTER.TERMS' | translate }}</a>&nbsp;
      <a (click)="disclaimerClick()" id="disclaimerLink" class="link inline">{{ 'FOOTER.DISCLAIMER' | translate }}</a>&nbsp;
      <a href="https://www.ajg.com/gallagherre/privacy-notice/" target="_blank" id="privacyLink" class="link inline">{{ 'FOOTER.PRIVACY' | translate }}</a>
    </div>

    <div class="copyright ms-3">
      Copyright &copy; {{currentYear}} Arthur J. Gallagher & Co.
    </div>

  </div>
</footer>
<kendo-dialog
      *ngIf="opened" class="legal-modal"
      width="75vw" height="75vh" id="legal-modal">
      <div class="terms-content" [innerHtml]="safeHtmlContent">
      </div>
</kendo-dialog>