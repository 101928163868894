import { Component} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UI_LANGUAGES } from './model/languages';
import { USER_PREFERENCES_INITIAL_STATE } from './model/userAccount';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{
  title = 'ajgre-marketsecurity-web';

  constructor(private translate: TranslateService) {
    var languages = UI_LANGUAGES.map(x => x.value);
    translate.addLangs(languages);
    translate.setDefaultLang(USER_PREFERENCES_INITIAL_STATE.language);
    translate.use(USER_PREFERENCES_INITIAL_STATE.language);
  }
}
