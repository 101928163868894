export enum Roles {
    internalUser = 'gateway-internal-user', // will appear for most internal employees using msec
    externalUser = 'gateway-external-user', // unknown for msec
    broker = 'msec-broker', //Readonly, internal user
    client = 'msec-client', //Readonly external user
    analyst = 'msec-analyst', //Analyst dashboard, ability to review/enact workflows
    admin = 'msec-admin' //Full rights to everything, inc. updating data and financials
}

export class UserRole {
    private _roles: Roles[] = [];

    constructor(userRoles: Roles[]) {
        this._roles = userRoles;
    }

    public IsAdmin(): boolean {
        return this.IsInRole(Roles.admin);
    }

    public IsBroker(): boolean {
        return this.IsInRole(Roles.broker);
    }

    public IsExternal(): boolean {
        return this.IsInRole(Roles.externalUser) || this.IsInRole(Roles.client); // clients are externla
    }

    public IsInternal(): boolean {
        return this.IsInRole(Roles.internalUser);
    }

    public IsInternalRoleMember(): boolean {
        return !this.IsExternal() && (         
               this.IsInRole(Roles.admin) 
            || this.IsInRole(Roles.broker) 
            || this.IsInRole(Roles.analyst)
        );
    }

    public IsClient(): boolean {
        return this.IsInRole(Roles.client);
    }

    public IsAnalyst(): boolean {
        return this.IsInRole(Roles.analyst);
    }

    public IsInRole(role: Roles) {
        return this._roles.indexOf(role) >= 0;
    }

    public IsAnyRole(roles: Roles[]) {
        for (let i = 0; i < roles.length; i++) {
            if(this._roles.indexOf(roles[i]) >= 0) {
                return true;
            }            
        }
        return false;
    }
}

export const USER_ROLE_INITIAL_STATE = new UserRole([]);

