export const UI_LANGUAGES: Array<LanguageSelection> = [
  { text: 'English (US)', flagImage: '/assets/images/flags/US.svg', value: 'en-US' },
  { text: 'English (UK)', flagImage: '/assets/images/flags/UK.svg', value: 'en-GB' },
  { text: 'Español', flagImage: 'assets/images/flags/spain-flag-round-icon svg.svg', value: 'es-ES' },
  { text: 'Français', flagImage: '/assets/images/flags/France.svg', value: 'fr-FR' },
  { text: 'Italiano ', flagImage: '/assets/images/flags/Italy.svg', value: 'it-IT' },
  { text: 'Português (BR)', flagImage: '/assets/images/flags/Brazil.svg', value: 'pt-BR' },
  { text: 'Português (PT)', flagImage: '/assets/images/flags/Portugal.svg', value: 'pt-PT' },
  { text: '中國普通話', flagImage: '/assets/images/flags/China.svg', value: 'zh-CN' },
];


export const UI_LANGUAGE_DEFAULT: LanguageSelection = UI_LANGUAGES[0];

export const UI_LANGUAGE_LOADING: LanguageSelection = {
  value: UI_LANGUAGE_DEFAULT.value,
  text: 'Loading..',
  flagImage: ''
};

export interface LanguageSelection {
  text: string;
  flagImage: any;
  value: string;
}
